@import "../../app.scss";

.featured {
  height: 76vh;
  position: relative;
  text-align: center;

  .category {
    position: relative;
    top: 80px;
    left: 50px;
    font-size: 30px;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;

    select {
      cursor: pointer;
      background-color: var(--main-color);
      border: 1px solid white;
      color: white;
      margin-left: 20px;
      padding: 5px;
    }
  }

  #genre {
    z-index: 2;
  }

  img,
  video {
    // width: 100%;
    height: 100%;
    // min-height: 450px;
    // object-fit: cover;
  }
  video::-internal-media-controls-download-button {
    display: none;
  }

  .info {
    // width: 35%;
    position: absolute;
    left: 50px;
    // top: 100px;
    bottom: 0px;
    color: white;
    display: flex;
    flex-direction: column;

    img,
    video {
      width: 40vw;
      max-height: 20vw;
    }

    .desc {
      margin: 20px 0px;

      @include mobile {
        width: 60vw;
      }
    }

    .title {
      font-size: 3em;
    }

    .buttons {
      display: flex;

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        margin-right: 10px;
        cursor: pointer;

        &.play {
          background-color: white;
          color: var(--main-color);
        }

        &.more {
          background-color: gray;
          color: white;
        }

        span {
          margin-left: 5px;
        }
      }
    }
  }
}
