.listItem {
  // width: 225px;
  height: 50vh;
  background-color: var(--main-color);
  cursor: pointer;
  color: white;

  video.poster, img.poster {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video.hover {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .hover {
    position: relative;
    top: -90px;
    background-color: transparent;
    // bottom: 0px;
    // z-index: 1;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.75);
    box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.75);
    border-radius: 5px;

    video {
      height: 250px;
      object-fit: cover;
      width: 100%;
    }
  }
}
