.billing {
  // margin-top: 100px;
  background-color: #1b2635;
  color: white;

  .body {
    margin: 0px 10px;
    .section {
      margin: 10px;
      padding: 10px;
      height: calc(400px + 20px + 20px);
      background-color: #233044;
      border-radius: 0.25em;
      span {
        font-size: 1.5em;
      }
      h1 {
        text-align: end;
      }
      div.grafo {
        height: calc(100% - 25px);
        .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
          display: none;
        }
      }
    }
    .section.middle {
      height: 200px;
    }
  }
}
