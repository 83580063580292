body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button.button {
  background-color: #cf79e0;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  &:disabled {
    Opacity: 0.5;
    cursor: not-allowed;
  }
}

:root {
  --main-color: #1b2635; //#0b0b0b;
}

.link {
  color: inherit;
  text-decoration: none;
}

@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}