.createPublication {
  margin-top: 10px;
  input[type="text"],
  textarea {
    margin: 3px 0;
  }
  textarea {
    padding: 10px 5px;
    resize: vertical;
    width: calc(100% - 12px);
  }
  .bottomsFotter {
    margin: 5px 0;
    text-align: end;
    button {
      color: white;
      padding: 5px 10px;
      margin: 0px 2px;
      border-radius: 0.5em;
      background-color: #ff3588;
      border: none;
      span {
        margin-left: 2px;
      }
    }
  }
  .zoneButtonTop {
    display: flex;
    justify-content: space-between;
  }
  .import {
    margin: 5px 0px;
    select {
      padding: 10px 5px;
      width: 100%;
      // text-align: center;
    }
  }
  .dropzone {
    min-height: 100px;
    max-height: calc(100vh - 450px);//50vh;
    overflow-y: auto;
    border: 1px dashed #aaa;
    border-radius: 0.5em;
    padding: 10px;
    margin: 10px 0px;
    .preview {
      display: flex;
      flex-wrap: wrap;
      .dropped {
        margin: 2px;
        max-height: 40vh;
        flex-grow: 1;
        img {
          max-height: 100%;
          width: 100%;
          object-fit: cover;
          vertical-align: bottom;
        }
        video {
          max-height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .linearProgress {
    text-align: center;
  }
}
