.itemInfo {
  background-color: #0b0b0b77;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 5px;
  // width: 100%;
  overflow-x: hidden;

  span {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icons {
    margin-bottom: 10px;
    .icon {
      border: 2px solid white;
      padding: 5px;
      border-radius: 50%;
      margin-right: 10px;
      font-size: 16px;
    }

    .icon.active {
      color: #382de5;
    }

    .itemInfoTop {
      // display: flex;
      // align-items: center;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 600;
      color: gray;
      overflow: hidden;

      .limit {
        border: 1px solid gray;
        padding: 1px 3px;
        margin: 0 10px;
      }
    }
  }

  .desc {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .genre {
    font-size: 14px;
    color: lightgray;
  }
}

.dialogVideo {
  max-height: 90vh;
  max-width: 90vw;
  video {
    max-height: 90vh;
    max-width: 85vw;
    // object-fit: fill;
  }
}
