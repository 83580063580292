.register {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 80%,
      #000000 100%
    ),
    url("https://images.pexels.com/photos/1470405/pexels-photo-1470405.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=1000");
  background-size: cover;
  position: relative;

  .top {
    .wrapper {
      padding: 20px 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        height: 40px;
      }

      .loginButton {
        background-color: #cf79e0;
        border: none;
        color: white;
        border-radius: 5px;
        padding: 5px 15px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

  .container {
    width: 100%;
    // height: 100%;
    // position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    h1 {
      font-size: 50px;
      text-align: center;
    }

    h2 {
      margin: 20px;
    }

    p {
      font-size: 20px;
    }

    .input {
      // width: 50%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      height: 50px;
      border-radius: 5px;

      input{
          flex: 9;
          height: 100%;
          border: none;
          padding: 0 10px;
      }

      .registerButton{
          flex: 3;
          height: 100%;
          background-color: #cf79e0;
          border: none;
          color: white;
          font-size: 22px;
          cursor: pointer;
      }
    }
  }
}
