.list {
  width: 98%;
  padding: 0px 0px 10px;
  margin: 25px auto;
  border-radius: 0.5em;
  background-color: #233044;
  // -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.75);
  // box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.75);

  .listTop {
    display: flex;
    justify-content: space-evenly;
    padding: 30px 0 15px;
    color: white;
    .listTitle {
      color: white;
      font-size: 20px;
      font-weight: 500;
    }
    button {
      color: white;
      padding: 5px 20px;
      margin: 2px 10px;
      border-radius: 0.5em;
      background-color: #ff3588;
      border: none;
      cursor: pointer;
    }
  }

  .listBottom {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px 0;
    color: white;

    .reactionsCounts {
      white-space: nowrap;
    }
    .icons {
      // margin-bottom: 10px;
      .icon {
        border: 2px solid white;
        padding: 5px;
        border-radius: 50%;
        margin-right: 10px;
        font-size: 16px;
      }

      .icon.active {
        color: #382de5;
      }
    }
  }

  .wrapper {
    position: relative;
    .sliderArrow {
      width: 50px;
      height: 100%;
      background-color: rgb(22, 22, 22, 0.5);
      color: white;
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }
    }
    // .container {
    //   margin-left: 50px;
    //   display: flex;
    //   margin-top: 10px;
    //   width: max-content;
    //   transform: translateX(0px);
    //   transition: all 1s ease;
    // }
  }
  .listDescription {
    color: white;
    text-align: center;
    margin: 5px;
    p {
      margin: 0px;
    }
  }
  .listComments {
    border: 1px #898888 solid;
    border-radius: 0.25em;
    width: 98%;
    margin: 0 auto;
    color: white;
    h3 {
      text-align: center;
    }
    .coment {
      padding: 0 5%;
      span {
        font-size: 0.8em;
      }
      strong {
        margin: 2px 5px;
      }
    }
  }
}
.bottomsFotter {
  text-align: center;
  button {
    color: white;
    padding: 5px 10px;
    margin: 2px;
    border-radius: 0.5em;
    background-color: #ff3588;
    border: none;
    cursor: pointer;
  }
  button.btnClose {
    background-color: #6c29e9;
  }
}

.list-topCredits {
  text-align: end;
  svg,span {
    vertical-align: middle;
  }
  svg {
    margin: 0px 5px;
  }
}

.listInputArea {
  // width: 50%;
  textarea {
    resize: none;
    width: 100%;
  }
}
