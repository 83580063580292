.grafo {
  .refreshButton {
    text-align: end;
  }
  ul.queueList {
    list-style-type: none;
    padding: 0px;
    text-align: center;
    svg {
      color: #fff;
    }
    li {
      border-bottom: 1px dotted #fff;
      display: flex;
      justify-content: space-between;
      padding: 10px 2px;
    }
  }
}
