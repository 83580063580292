.username {
  // margin-top: 150px;
  padding-top: 100px;
  background-color: var(--main-color);
  overflow: hidden;
  color: white;

  .top {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .usernameTitle {
      display: flex;
      align-items: center;
    }
    .follow {
      button {
        color: white;
        padding: 5px 10px;
        margin: 2px;
        border-radius: 0.5em;
        background-color: #ff3588;
        border: none;
      }
    }
  }

  .title {
    text-align: center;
    span {
      margin: 5px 15px;
    }
  }
}
