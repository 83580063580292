.createrVideoChat {
  .room {
    > div {
      padding: 10px;
    }
    .camera {
      video {
        width: 100%;
        max-height: calc(100vh - 100px);
      }
    }
    .messages {
      display: flex;
      flex-direction: column-reverse;
      .messagesList {
        // max-height: calc(1em - 200px);
        // @media (max-width: 960px) {
        //   max-height: calc(1em - 200px);
        // }
        overflow-y: auto;
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          color: #fff;
          li {
            display: flex;
            border-radius: 0.25em;
            background-color: #1f1f1f5c;
            margin-top: 10px;
            strong {
              margin-right: 5px;
            }
            .Avatar {
              padding-left: 10px;
              padding-top: 10px;
            }
            .content {
              width: 100%;
              > div {
                padding: 0px 10px;
              }
              .content-text {
                // float: left;
                text-align: justify;
                overflow-x: auto;
              }
              .content-report {
                text-align: right;
                padding-bottom: 10px;
              }
            }
          }
        }
      }
      .messagesInput {
        border: 1px solid grey;
        border-radius: 0.25em;
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        padding: 10px;
        input[type="text"] {
          border: none;
          flex-grow: 2;
        }
        input:focus {
          outline: none;
        }
      }
    }
  }
}
