.login {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("https://images.pexels.com/photos/1470405/pexels-photo-1470405.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=1000");
  background-size: cover;
  position: relative;

  .top {
    color: white;
    .wrapper {
      padding: 20px 0px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      .logo {
        height: 40px;
      }
    }
  }

  .container {
    width: 100%;
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    form {
      width: 300px;
      height: 300px;
      padding: 30px;
      border-radius: 5px;
      background-color: var(--main-color);
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      input {
        height: 40px;
        border-radius: 5px;
        background-color: gray;
        color: white;
        padding-left: 10px;

        &::placeholder {
          color: lightgray;
        }
      }

      // button {
      //   height: 40px;
      //   border-radius: 5px;
      //   background-color: red;
      //   color: white;
      //   border: none;
      //   font-size: 18px;
      //   font-weight: 500;
      //   cursor: pointer;
      // }

      span {
        color: lightgray;
        b {
          color: white;
        }
      }
    }
  }
}
